import React from 'react'

const Home = () => {
  return (
    <div className="min-h-75 container mx-auto flex justify-center items-center flex-col text-justify">
      <p className="md:text-2xl xs:text-xl my-5 md:w-2/4 xs:w-full">En<strong> Blog Virtual </strong> publicamos el proceso reorganización del JUZGADO 22 CIVIL DEL CIRCUITO de Bogotá, Referencia. 11001 3101 022 2023 00194 00</p>
    </div>

  )
}

export default Home
