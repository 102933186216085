import React from 'react'

const Home = () => {
    return (
        <div className="min-h-75 container mx-auto flex justify-center items-center flex-col text-justify">
            <p className="md:text-2xl xs:text-xl my-5 md:w-2/4 xs:w-full">En<strong> Blog Virtual </strong> publicamos la negociación para la celebración de un acuerdo privado extrajudicial de reorganización persona natural comerciante.
            <br/>
(ley 1116 de 2006 y decreto 1730 de 2009).</p>
        </div>

    )
}

export default Home
